<template>
  <b-container
    fluid
    class="mb-5"
  >
    <div class="d-flex justify-content-end mb-3 pb-0">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-card>
      <b-row class="mb-2">

        <b-col
          cols="12"
          md="9"
        >
          <div class="form-group">
            <label>
              <strong>Search</strong>
            </label>
            <b-input
              v-model="tableDrivers.filter.search"
              type="text"
              placeholder="search here"
              debounce="1000"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          md="3"
        >
          <div class="form-group">
            <label>
              <strong>License Type</strong>
            </label>
            <b-select
              v-model="tableDrivers.filter.license_type"
              :options="filterLicenseType"
            />
          </div>
        </b-col>

        <b-col cols="12">
          <b-button
            type="button"
            variant="success"
            autocomplete="off"
            @click="onCreate"
          >
            Create Driver
          </b-button>
        </b-col>
      </b-row>
      <b-table
        ref="table"
        class="mt-2"
        :per-page="tableDrivers.perPage"
        :current-page="tableDrivers.currentPage"
        :items="tableProvider"
        :fields="tableDrivers.fields"
        :sort-by.sync="tableDrivers.sortBy"
        :sort-desc.sync="tableDrivers.sortDesc"
        :sort-direction="tableDrivers.sortDirection"
        :filter="tableDrivers.filter"
        :filter-included-fields="tableDrivers.filterOn"
        show-empty
        hover
        responsive
      >
        <template #cell(index)="data">
          {{ tableDrivers.currentPage * tableDrivers.perPage - tableDrivers.perPage + (data.index + 1) }}
        </template>
        <template #cell(active)="data">
          {{ data.item.active == 1 ? 'Yes' : 'No' }}
        </template>
        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>
        <template #cell(action)="row">
          <b-button
            size="sm"
            variant="primary"
            @click="onEdit(row.item)"
          >
            Edit
          </b-button>
        </template>
      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableDrivers.perPage"
              :options="tableDrivers.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableDrivers.currentPage"
            :total-rows="tableDrivers.totalRows"
            :per-page="tableDrivers.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="modal-driver-form"
      scrollable
      no-close-on-backdrop
      no-enforce-focus
      aria-hidden="false"
      :title="modalTitle"
      @ok="onValidate"
    >
      <ValidationObserver ref="formDriver">
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <ValidationProvider
            #default="{ errors }"
            name="first name"
            vid="first_name"
            rules="required|max:50"
          >
            <div class="form-group">
              <label for="first_name">First Name</label>
              <b-input
                id="first_name"
                v-model="driver.first_name"
                type="text"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
                placeholder="enter first name"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider
            #default="{ errors }"
            name="last name"
            vid="last_name"
            rules="required|max:50"
          >
            <div class="form-group">
              <label for="last_name">Last Name</label>
              <b-input
                id="last_name"
                v-model="driver.last_name"
                type="text"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
                placeholder="enter last name"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider
            #default="{ errors }"
            name="email"
            vid="email"
            rules="email|max:100"
          >
            <div class="form-group">
              <label for="email">Email</label>
              <b-input
                id="email"
                v-model="driver.email"
                type="email"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
                placeholder="enter email"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider
            #default="{ errors }"
            name="mobile number"
            vid="mobile_number"
            rules="min:11|max:11|regex:[0]{1}[9]{1}[0-9]{9}"
          >
            <div class="form-group">
              <label for="mobile_number">Mobile Number</label>
              <b-input
                id="mobile_number"
                v-model="driver.mobile_number"
                type="text"
                maxlength="11"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
                placeholder="enter mobile number"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider
            #default="{ errors }"
            name="permanent address"
            vid="permanent_address"
            rules="max:300"
          >
            <div class="form-group">
              <label for="permanent_address">Permanent Address</label>
              <b-form-textarea
                id="permanent_address"
                v-model="driver.permanent_address"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
                placeholder="enter permanent address"
                rows="3"
                max-rows="6"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider
            #default="{ errors }"
            name="present address"
            vid="present_address"
            rules="max:300"
          >
            <div class="form-group">
              <label for="present_address">Present Address</label>
              <b-form-textarea
                id="present_address"
                v-model="driver.present_address"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
                placeholder="enter present address"
                rows="3"
                max-rows="6"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider
            #default="{ errors }"
            name="license type"
            vid="license_type"
            rules="required"
          >
            <div class="form-group">
              <label for="license_type">License Type</label>
              <b-form-select
                id="license_type"
                v-model="driver.license_type"
                :options="list.licenseType"
                type="text"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
              >
                <template #first>
                  <b-form-select-option
                    :value="null"
                    disabled
                  >
                    -- Please Select License Type --
                  </b-form-select-option>
                </template>
              </b-form-select>
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider
            #default="{ errors }"
            name="license number"
            vid="license_number"
            rules="max:50"
          >
            <div class="form-group">
              <label for="license_number">License Number</label>
              <b-input
                id="license_number"
                v-model="driver.license_number"
                type="text"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
                placeholder="enter license number"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
          <ValidationProvider
            #default="{ errors }"
            name="license expiry"
            vid="license_expiry"
          >
            <div class="form-group">
              <label for="license_expiry">License Expiry</label>
              <b-input
                id="license_expiry"
                v-model="driver.license_expiry"
                type="date"
                :state="errors.length > 0 ? false : null"
                :disabled="state.busy"
                autocomplete="off"
              />
              <div
                v-if="errors.length > 0"
                class="invalid-feedback"
              >
                <span>{{ errors[0] }}</span>
              </div>
            </div>
          </ValidationProvider>
          <div
            v-if="state.editing"
            class="form-group"
          >
            <b-form-checkbox
              id="is_active"
              v-model="driver.active"
              name="is_active"
              value="1"
              unchecked-value="0"
              switch
              :disabled="state.busy"
            >
              Is Active?
            </b-form-checkbox>
          </div>
        </form>
      </ValidationObserver>

      <template #modal-footer="{ok, cancel}">
        <b-button
          variant="success"
          @click="ok()"
        >
          {{ state.editing ? 'Update Record' : 'Save Record' }}
        </b-button>
        <b-button
          variant="outline-dark"
          @click="cancel()"
        >
          Close Window
        </b-button>
      </template>
    </b-modal>

  </b-container>
</template>
<script>
import _ from 'lodash'
import { core } from '@/config/pluginInit'
import { AxiosError, SLODriver } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'SLODrivers',

  middleware: ['auth', 'logistics-officer'],

  metaInfo () {
    return {
      title: 'Drivers'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        editing: false,
        busy: false
      },
      list: {
        licenseType: [
          { text: 'Non Professional', value: 'Non Professional' },
          { text: 'Professional', value: 'Professional' }
        ]
      },
      driver: {
        id: null,
        active: 0,
        first_name: '',
        last_name: '',
        license_number: '',
        license_expiry: '',
        license_type: null,
        email: '',
        mobile_number: '',
        present_address: '',
        permanent_address: ''
      },
      tableDrivers: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: {
          license_type: 'All',
          search: ''
        },
        filterOn: [],
        fields: [
          'index',
          { key: 'action', class: 'text-center' },
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'first_name', sortable: true },
          { key: 'last_name', sortable: true },
          { key: 'mobile_number', sortable: true },
          { key: 'email', sortable: true },
          { key: 'license_type', sortable: true },
          { key: 'active', class: 'text-center' },
          { key: 'updated_at', formatter: this.dateTimeFormatter, sortable: true }
        ]
      }
    }
  },

  computed: {
    modalTitle () {
      return this.state.editing ? 'Edit Driver' : 'New Driver'
    },
    filterLicenseType () {
      return [{ value: 'All', text: 'All' }].concat(this.list.licenseType)
    }
  },

  mounted () {
    core.index()
  },

  methods: {
    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      const filters = this.objectToUrl({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_license_type: filter.license_type,
        filter_text: filter.search
      })

      return await SLODriver.get(filters).then(
        ({ data }) => {
          this.tableDrivers.totalRows = data.total_rows
          return data.items
        }
      ).catch(() => {
        this.tableDrivers.totalRows = 0
        return []
      })
    },

    onCreate () {
      this.state.editing = false
      this.driver.id = null
      this.driver.active = 1
      this.driver.first_name = ''
      this.driver.last_name = ''
      this.driver.license_number = ''
      this.driver.license_expiry = ''
      this.driver.license_type = null
      this.driver.email = ''
      this.driver.mobile_number = ''
      this.driver.present_address = ''
      this.driver.permanent_address = ''
      this.$bvModal.show('modal-driver-form')
    },

    onEdit (item) {
      this.state.editing = true
      this.driver.id = item.id
      this.driver.active = item.active
      this.driver.first_name = item.first_name
      this.driver.last_name = item.last_name
      this.driver.license_number = item.license_number
      this.driver.license_expiry = item.license_expiry
      this.driver.license_type = item.license_type
      this.driver.email = item.email
      this.driver.mobile_number = item.mobile_number
      this.driver.present_address = item.present_address
      this.driver.permanent_address = item.permanent_address
      this.$bvModal.show('modal-driver-form')
    },

    async onValidate (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.formDriver.validate().then(
        async areAllFieldsValid => {
          if (areAllFieldsValid) {
            this.$swal.fire({
              icon: 'question',
              title: 'Confirmation',
              text: this.state.editing ? 'Update Driver?' : 'Create Driver?',
              confirmButtonColor: '#06C270',
              confirmButtonText: 'Confirm',
              cancelButtonColor: '#737373',
              showCancelButton: true,
              showLoaderOnConfirm: true,
              preConfirm: () => {
                this.state.busy = true
                if (this.state.editing) {
                  return this.onPut()
                }

                return this.onPost()
              },
              allowOutsideClick: () => !this.$swal.isLoading()
            })
          } else {
            this.$bvModal.msgBoxOk('Oops! Your inputs seem to be incorrect or incomplete, please check and try again.', {
              title: 'Validation Error',
              size: 'sm',
              buttonSize: 'sm',
              okVariant: 'danger',
              centered: true
            })
          }
        }
      )
    },

    async onPost () {
      return new Promise(
        resolve => {
          SLODriver.post(this.driver).then(
            ({ data }) => {
              this.$bvModal.hide('modal-driver-form')
              this.$swal.fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              }).then(() => {
                this.$refs.table.refresh()
              })
            }
          ).catch(error => {
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.formDriver.setErrors(error.message)
              }
            }
          }).finally(resolve)
        }
      ).then(() => {
        this.state.busy = false
      })
    },

    async onPut () {
      return new Promise(
        resolve => {
          SLODriver.put(this.driver).then(
            ({ data }) => {
              this.$bvModal.hide('modal-driver-form')
              this.state.busy = false
              this.$swal.fire({
                icon: 'success',
                title: 'Successful',
                text: data.message,
                confirmButtonColor: '#06C270',
                confirmButtonText: 'Dismiss'
              }).then(() => {
                const row = _.find(this.$refs.table.localItems, {
                  id: this.driver.id
                })
                row.active = data.driver.active
                row.first_name = data.driver.first_name
                row.last_name = data.driver.last_name
                row.license_number = data.driver.license_number
                row.license_expiry = data.driver.license_expiry
                row.license_type = data.driver.license_type
                row.email = data.driver.email
                row.mobile_number = data.driver.mobile_number
                row.present_address = data.driver.present_address
                row.permanent_address = data.driver.permanent_address
                row.updated_at = data.driver.updated_at
              }).finally(resolve)
            }
          ).catch(
            error => {
              if (error instanceof AxiosError) {
                if (error.code === 422) {
                  this.$refs.formDriver.setErrors(error.message)
                }
              }
            }
          )
        }
      )
    }
  }
}
</script>
